export const getLanguage = (language, setVariables) => {

  switch (language) {
    case 'pl-PL':
      setVariables({
        title: 'To nie jest miejsce dla przeciętnego mężczyzny',
        subTitle: 'Kliknij poniższy przycisk i dołącz do ekskluzywnej strefy VIP',
        button: 'Odblokuj Dostęp!',
        siteTitle: 'Prywatna Strefa VIP',
      });
      break;

    case 'no-NO':
      setVariables({
        title: "Eksklusive Dater",
        subTitle: "Finn en spesiell person i vår eksklusive krets",
        button: "Bli med nå",
        siteTitle: "Eksklusive Dater - Finn din match i dag!"
      });
      break;
      
    case 'en-GB':
    case 'en-CA':
    case 'en-AU':
    case 'en-US':
    default:
      setVariables({
        title: 'This is not a place for the average man',
        subTitle: 'Click the button below and join the exclusive VIP zone',
        button: 'Unlock Access!',
        siteTitle: 'Private VIP Zone',
      });
      break;

    case 'de-DE':
      setVariables({
        title: 'Dies ist kein Ort für den Durchschnittsmann',
        subTitle: 'Klicken Sie auf die Schaltfläche unten und treten Sie der exklusiven VIP-Zone bei',
        button: 'Zugang freischalten!',
        siteTitle: 'Private VIP-Zone',
      });
      break;

    case 'sv-SE':
      setVariables({
        title: 'Detta är inte en plats för den genomsnittlige mannen',
        subTitle: 'Klicka på knappen nedan och gå med i den exklusiva VIP-zonen',
        button: 'Lås upp åtkomst!',
        siteTitle: 'Privat VIP-zon',
      });
      break;

    case 'it-IT':
      setVariables({
        title: 'Questo non è un posto per l\'uomo medio',
        subTitle: 'Clicca il pulsante qui sotto e unisciti alla zona VIP esclusiva',
        button: 'Sblocca l\'accesso!',
        siteTitle: 'Zona VIP privata',
      });
      break;
      
    case 'de-CH':
      setVariables({
        title: 'Dies ist kein Ort für den Durchschnittsmann',
        subTitle: 'Klicken Sie auf die Schaltfläche unten und treten Sie der exklusiven VIP-Zone bei',
        button: 'Zugang freischalten!',
        siteTitle: 'Private VIP-Zone',
      });
      break;

    case 'es-ES':
    case 'es-MX':
      setVariables({
        title: 'Este no es un lugar para el hombre promedio',
        subTitle: 'Haz clic en el botón de abajo y únete a la zona VIP exclusiva',
        button: '¡Desbloquea el acceso!',
        siteTitle: 'Zona VIP privada',
      });
      break;
  }
};
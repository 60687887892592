import './App.css';
import { getLanguage } from './Language';
import { getLink } from './Links'
import MediaQuery from 'react-responsive';
import { useState, useEffect } from 'react';
import ReactGA from "react-ga4";

// Zmienne główne dla ustawień strony
const randomBackgroundImageE = false;   // Random dla zdjęć tła 
const randomBackgroundColorE = true;    // Random dla koloru tła 

const TRACKING_ID = "G-13WXXZ2FC8"; //Google Analytics
const icon = ['hearth1', 'hearth2', 'lips1', 'lips2', 'lips3', 'sex1', 'tinder', 'sex2'];

const backgroundStyles = randomBackgroundImageE ? [
  {background: 'none', container: '#ffffffb5', titleColor: 'black', subTitleColor: 'black', buttonBackground: '#cd5050', buttonColor: '#ffffff', mask: '#e1e1e1'},
  {background: 'none', container: '#000000c2', titleColor: '#f1f1f1', subTitleColor: '#f1f1f1', buttonBackground: '#ffd700', buttonColor: '#1a1a2e', mask: '#0b0b0b'}
] : randomBackgroundColorE ? [
  {background: '#1a1a2e' , container: 'none', titleColor: '#ffd700', subTitleColor: '#e0e0e0', buttonBackground: '#ffd700', buttonColor: '#1a1a2e', mask: '#1a1a2e'}
] : [];

const randomNumber = Math.floor(Math.random() * 14) + 1;                    // Random dla zdjęć tła
const randomStyle = Math.floor(Math.random() * backgroundStyles.length);     // Random styli dla zdjęć tła
const randomIcon = Math.floor(Math.random() * icon.length);                 // Random splash

function App() {
  const [language, setLanguage] = useState(null);
  const [variables, setVariables] = useState({});
  const [afterSlash, setAfterSlash] = useState('');
  const [link, setLink] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setCountry(data.country_name);
      })
      .catch(error => console.error('Error fetching IP geolocation data:', error));

    const url = window.location.href;
    setAfterSlash(url.substring(url.lastIndexOf('/') + 1));

    const browserLanguage = navigator.language;
    setLanguage(browserLanguage);

    ReactGA.initialize(TRACKING_ID);
  }, []);

  useEffect(() => {
    if (language) {
      getLanguage(language, setVariables);
    }
  }, [language]);

  useEffect(() => {
    if (country) {
      getLink(country, afterSlash, setLink);
    }
  }, [country, afterSlash]);

  useEffect(() => {
    const updateBackground = () => {
      const bgType = window.innerWidth >= 1225 ? 'bg' : 'bg-mobile';
      if (randomBackgroundImageE) {
        document.body.style.backgroundImage = `url(img/${bgType}/bg${randomNumber}.${bgType === 'bg' ? 'jpg' : 'png'})`;
      } else if (randomBackgroundColorE) {
        document.body.style.backgroundColor = backgroundStyles[randomStyle].background;
      }
    };
  
    window.addEventListener('resize', updateBackground);
    updateBackground();
  
    return () => window.removeEventListener('resize', updateBackground);
  }, []);

  useEffect(() => {
    document.title = variables.siteTitle || '';
  }, [variables.siteTitle]);

  return (
    <>
      <MediaQuery minWidth={1225}>
        <Content variables={variables} style={backgroundStyles[randomStyle]} link={link} />
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <Content variables={variables} style={backgroundStyles[randomStyle]} link={link} />
      </MediaQuery>
    </>
  );
}

const Content = ({ variables, style, link }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPop, setIsPop] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
      const popTimer = setTimeout(() => {
        setIsPop(true);
      }, 3000);
      return () => clearTimeout(popTimer);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', !isLoaded);
    return () => document.body.classList.remove('no-scroll');
  }, [isLoaded]);

  return (
    <div>
      <div className={`mask ${isLoaded ? 'hidden' : 'visible'}`} style={{backgroundColor: style.mask}}></div>
      {!isLoaded && (
        <div className='loading'>
          <div className='icon'><img src={`icon/${icon[randomIcon]}.png`} alt="Loading icon" /></div>
        </div>
      )}
      <div className={`${isLoaded ? 'visible' : 'hidden'}`}>
        <div className='container' style={{ backgroundColor: style.container}}>
          <div className='title' style={{color: style.titleColor}}>{variables.title}</div>
          <div className='subtitle' style={{color: style.subTitleColor}}>{variables.subTitle}</div>
          <a href={link}>
            <div className='btn' style={{color: style.buttonColor, background: style.buttonBackground}} onClick={() => ReactGA.event({ category: 'Button', action: 'Click', label: 'Confirmation of age' })}>
              {variables.button}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;